import { Helmet } from "react-helmet-async";
import "./DisclaimerPage.css";

const DisclaimerPage = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer | Street Suite</title>
        <meta name="description" content="Street Suite –  Disclaimer." />
      </Helmet>
      {/* page to specify user responsibilities from a legal prespective */}
      <div className="disclaimerBG">
        <div className="md:w-10/12 sm:w-11/12 mx-auto disclaimerPage">
          <div className=" md:w-1/2 sm:w-4/5 mx-auto text-center">
            <div className="disclaimerHeader">
              <h1>Disclaimer</h1>
              <p>
                Please read this disclaimer before using our platform. It
                outlines important terms and conditions for your interaction
                with our services. By accessing our platform, you agree to
                adhere to these terms.{" "}
              </p>
            </div>
          </div>

          <div className="disclaimerContentParent">
            <div className="disclaimerContent">
              <h4>Disclaimer</h4>
              <p>
                Many of our awareness campaigns are paid via third parties,
                these third parties are often investor relations companies
                and/or market awareness companies who have been subsequently
                hired by the company or an investor/investor group that have
                shares in the company receiving the awareness.
              </p>
              <p>
                Paid awareness is nearly always a means to sell shares to the
                public in the open market, so you should always assume if there
                is an awareness campaign then someone is selling shares of the
                company in question.
              </p>
              <p>
                Stock profiles are intended to be stock ideas and not
                recommendations. We may also buy, sell, hold or accumulate
                shares in companies that are not necessarily profiled clients.
                Please do your own research before investing. It is critical
                that you at least look at current SEC/Edgar filings and read the
                latest press releases. Information disseminated is typically
                taken from current documents filed with the SEC/Edgar, the
                company website, and other publicly available sources deemed
                reliable. The purpose of advertisements, like any other form of
                advertising, is to provide marketing awareness for the company.
                The information provided in this advertisement is not intended
                for distribution to, or use by, any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.{" "}
              </p>
              <p>
                Penny Stocks Today is not a financial adviser or broker and does
                not offer financial advice based on your personal financial
                situation or goals and shall not be held liable for any
                investment or trading losses you may incur by using the
                information provided. This includes your trading the stocks
                mentioned on the Internet, various Internet chat rooms, message
                boards, video charts, in daily or weekly alerts, or any other
                company publications. None of the information contained on
                messages in various Internet chat rooms, message boards, video
                charts, in daily or weekly alerts, or any other company
                publications constitutes a recommendation to buy or sell a
                particular security. The content provided by the company is
                provided solely as an informational aid to your investment
                research and all investment decisions are solely the
                responsibility of the user. The information contained in my
                alerts, various Internet chat rooms, video chats, and other
                materials offered is believed to be accurate; however, I make no
                warranties or guarantees to its accuracy. All information is
                taken from SEC/Edgar filings, company websites, and Internet
                search engines and is believed to be deemed accurate and I take
                no responsibility if the information was not accurate and up to
                date
              </p>
            </div>
            <div className="disclaimerContent">
              <h4>Disclaimer</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute iru
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DisclaimerPage;
